import { useState, useEffect, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { ReactComponent as All } from '../../assets/lifts/all.svg';
import { ReactComponent as Chair } from '../../assets/lifts/chair.svg';
import { ReactComponent as Platter } from '../../assets/lifts/platter.svg';
import { ReactComponent as TBar } from '../../assets/lifts/t-bar.svg';
import { ReactComponent as Walkway } from '../../assets/lifts/walkway.svg';
import { ReactComponent as Gondola } from '../../assets/lifts/gondola.svg';
import { ReactComponent as Clock } from '../../assets/info/clock.svg';
import { ReactComponent as Length } from '../../assets/info/length.svg';
import { ReactComponent as Duration } from '../../assets/info/duration.svg';
import { ReactComponent as Last } from '../../assets/info/last_departure.svg';
import { ReactComponent as Traffic } from '../../assets/info/traffic.svg';

import './liftCard.scss';

type StationProps = {
  lift: {
    name: string;
    status: string;
    length: number;
    rideTime: number;
    lastRunUp: string;
    lastRunDown: string;
    traffic: string;
    liftType: string;
    openHours: {
      dayOfWeek: string;
      from: string;
      to: string;
    };
    openHoursWeek: [];
  };
};

type DayProps = {
  dayOfWeek: string;
  from: string;
  to: string;
};

const LiftCard = ({ lift }: StationProps) => {
  const { t } = useTranslation();

  const [showDropdown, setShowDropdown] = useState(false);
  const toggleShowDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const clickOutsideRef = useRef(null);
  useOutsideAlerter(clickOutsideRef);

  return (
    <div className="lift-card">
      <div className="lift-card__lift">
        <div className="lift-card__lift-title">
          {lift.liftType === 'CABLEWAY' && <All className="lift-card__lift-title-icon" />}
          {lift.liftType === 'FURNICULAR_RAILWAY' && <All className="lift-card__lift-title-icon" />}
          {lift.liftType === 'UNSUPPORTED_LIFT' && <All className="lift-card__lift-title-icon" />}
          {lift.liftType === 'BASKET_LIFT' && <All className="lift-card__lift-title-icon" />}
          {lift.liftType === 'CHAIRLIFT' && <Chair className="lift-card__lift-title-icon" />}
          {lift.liftType === 'PLATTER_LIFT' && <Platter className="lift-card__lift-title-icon" />}
          {lift.liftType === 'T_BAR_LIFT' && <TBar className="lift-card__lift-title-icon" />}
          {lift.liftType === 'MOVING_WALKWAY' && <Walkway className="lift-card__lift-title-icon" />}
          {lift.liftType === 'GONDOLA' && <Gondola className="lift-card__lift-title-icon" />}
          {lift.name}
        </div>
        <div className="lift-card__lift-time" ref={clickOutsideRef}>
          <Clock
            className={
              lift.status === 'OPEN'
                ? 'lift-card__lift-time-icon'
                : 'lift-card__lift-time-icon--red'
            }
          />
          {lift.status === 'OPEN' ? t('landing.statusOpen') : t('landing.statusClose')}:&nbsp;
          {lift.openHours === null && '-'}
          {lift.openHours !== null && (
            <>
              <button type="button" className="lift-card__button" onClick={toggleShowDropdown}>
                <span className="lift-card__list-item-text--bold">
                  {lift.openHours.from.slice(0, 5)} -&nbsp;{lift.openHours.to.slice(0, 5)}
                </span>
                <Arrow className="lift-card__button-arrow" />
              </button>
              <div
                className={
                  showDropdown ? 'lift-card__lift-dropdown' : 'lift-card__lift-dropdown--hide'
                }
              >
                <ul className="lift-card__lift-dropdown-list">
                  {lift.openHoursWeek?.map((day: DayProps) => (
                    <li
                      className={
                        day.dayOfWeek !== lift.openHours.dayOfWeek
                          ? 'lift-card__lift-dropdown-list-item'
                          : 'lift-card__lift-dropdown-list-item--bold'
                      }
                      key={day.dayOfWeek}
                    >
                      <div>
                        {day.dayOfWeek === 'MONDAY' && t('lifts.day.monday')}
                        {day.dayOfWeek === 'TUESDAY' && t('lifts.day.tuesday')}
                        {day.dayOfWeek === 'WEDNESDAY' && t('lifts.day.wednesday')}
                        {day.dayOfWeek === 'THURSDAY' && t('lifts.day.thursday')}
                        {day.dayOfWeek === 'FRIDAY' && t('lifts.day.friday')}
                        {day.dayOfWeek === 'SATURDAY' && t('lifts.day.saturday')}
                        {day.dayOfWeek === 'SUNDAY' && t('lifts.day.sunday')}:
                      </div>
                      <div>
                        {day.from.slice(0, 5)} - {day.to.slice(0, 5)}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>

      <ul className="lift-card__list">
        <li className="lift-card__list-item">
          <Length className="lift-card__list-item-icon" />
          <p className="lift-card__list-item-text">
            {t('lifts.length')}{' '}
            <span className="lift-card__list-item-text--bold">
              {lift.length !== null ? `${lift.length}m` : '-'}
            </span>
          </p>
        </li>
        <li className="lift-card__list-item">
          <Duration className="lift-card__list-item-icon" />
          <p className="lift-card__list-item-text">
            {t('lifts.duration')}{' '}
            <span className="lift-card__list-item-text--bold">
              {lift.rideTime !== null ? `${lift.rideTime}min` : '-'}
            </span>
          </p>
        </li>
      </ul>
      <ul className="lift-card__list">
        <li className="lift-card__list-item">
          <Traffic className="lift-card__list-item-icon" />
          <p className="lift-card__list-item-text">
            {t('lifts.traffic')}{' '}
            <span className="lift-card__list-item-text--bold">
              {lift.traffic === null && '-'}
              {lift.traffic === 'NONE' && t('lifts.trafficStatus.none')}
              {lift.traffic === 'SCARCE' && t('lifts.trafficStatus.scarce')}
              {lift.traffic === 'LOW' && t('lifts.trafficStatus.low')}
              {lift.traffic === 'MODERATE' && t('lifts.trafficStatus.moderate')}
              {lift.traffic === 'HIGH' && t('lifts.trafficStatus.high')}
              {lift.traffic === 'OVERLOADED' && t('lifts.trafficStatus.overloaded')}
            </span>
          </p>
        </li>
        <li className="lift-card__list-item">
          <Last className="lift-card__list-item-icon" />
          <p className="lift-card__list-item-text">
            {t('lifts.last')}{' '}
            <span className="lift-card__list-item-text--bold">
              {lift.lastRunUp !== null ? lift.lastRunUp.substring(0, 5) : '-'}
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

export default LiftCard;
